import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ //const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class POFUService {
    
    async create(data) {
        try {
            const res = await axios.post(API_URL + 'pofu', data,
            {
                headers: AuthService.authHeader()
            })
            console.log(res.data)
            return res.data
            
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }

    }

    async getAll(rec, tl, client, loc, source, empid, role, ids ) {
        try {
            const res = await axios.get(API_URL + 'pofu/list?rec=' + rec + '&tl=' + tl + '&client=' + client + '&loc=' + loc + '&source=' + source  + '&empid=' + empid  + '&role=' + role + '&rec_ids=' + ids,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    // async getById(id) {
    //     try {
    //         const res = await axios.get(API_URL + 'interview/' + id,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }
    async getCandidateJobPOFUs(cid, jid, pid) {
        try {
            const res = await axios.get(API_URL + "pofu/" + jid + "/" + cid + "/" + pid , 
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    // async update(cid,jid,data) {
    //     try {
    //         const res = await axios.put(API_URL + 'interview?candidate_id='+ cid +'&job_id='+ jid, data,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }

    // async delete(cid,jid) {
    //     try {
    //         const res = await axios.delete(API_URL + 'interview?candidate_id='+ cid +'&job_id='+ jid,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }

}

export default new POFUService();